import React, { useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { Box, Tabs, Tab, Typography, Paper } from '@mui/material';
import Login from './Login';
import ChangePassword from './ChangePassword';

export default function Profile() {
  const { user } = useAuth();
  const [tabIndex, setTabIndex] = useState(0);

  // Gestion du changement de tab
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        p: 4,
        boxSizing: 'border-box',
      }}
    >
      {user ? (
        <Paper 
          sx={{
            width: '100%',
            maxWidth: 800,
            boxShadow: 3,
            borderRadius: 2,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Typography
  variant="h3"
  textAlign="center"
  color="primary"
  sx={{
    fontSize: {
      xs: '1.5rem', // Petite taille d'écran (smaller than 600px)
      sm: '2.5rem', // Écran de taille moyenne
      md: '3rem', // Taille d'écran standard (moyenne)
    },
    fontWeight: 'bold',
  }}
>
  EXCHANGE.COM
</Typography>

          <hr className="bg-slate-400 h-1 w-full my-2" />

          {/* Tabs pour le profil et le changement de mot de passe */}
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="Profile Tabs"
            sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}
            centered
          >
            <Tab label="Profil" />
            <Tab label="Changer le mot de passe" />
          </Tabs>

          {/* Contenu des tabs */}
          {tabIndex === 0 && (
            <Box sx={{ mt: 2,height:400 }}>
              <Typography variant="h5" className="font-bold">
                Name: {user?.user.name}
              </Typography>
              <Typography>Email: {user?.user.email}</Typography>
              <Typography>
                Created At: {new Date(user?.user.created_at).toLocaleDateString()}
              </Typography>
            </Box>
          )}

          {tabIndex === 1 && (
            <Box sx={{ mt: 2 }}>
              <ChangePassword />
            </Box>
          )}
        </Paper>
      ) : (
        <Login />
      )}
    </Box>
  );
}
