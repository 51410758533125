import { createContext, useContext, useState } from 'react';
import axios from '../axios';

const appUrl = process.env.REACT_APP_URL;
const AuthContent = createContext({
	user: null,
	setUser: () => {},
	csrfToken: () => {},
});

export const AuthProvider = ({ children }) => {
	const [user, _setUser] = useState(
		JSON.parse(localStorage.getItem('user')) || null
	);

	// set user to local storage 
	const setUser = (user) => {
		if (user.remember) {
			localStorage.setItem('user', JSON.stringify(user));
		} else {
			localStorage.removeItem('user');
		}
		_setUser(user);
	};

	// csrf token generation for guest methods
	const csrfToken = async () => {
		//await axios.get('http://localhost:8000/sanctum/csrf-cookie');
		//await axios.get('https://exchange-maroc.com/laravel/sanctum/csrf-cookie');
		await axios.get(`${appUrl}/sanctum/csrf-cookie`);
		return true;
	};

	//ajouter pour modif password
	const changePassword = async (currentPassword, newPassword, confirmPassword) => {
		try {
		  // Assurez-vous que le token CSRF est généré
		  await csrfToken();
	  
		  // Envoyer une requête PUT ou POST au backend Laravel pour changer le mot de passe
		  const response = await axios.put(`${appUrl}/api/changepassword`, {
			current_password: currentPassword,
			new_password: newPassword,
			new_password_confirmation: confirmPassword,
		  });
	  
		  return response.data;
		} catch (error) {
		  // Gérer les erreurs (par exemple, mot de passe incorrect, validation échouée)
		  console.error(error);
		  throw error;
		}
	  };
	////	  

	return (
		<AuthContent.Provider value={{ user, setUser, csrfToken, changePassword }}>
			{children}
		</AuthContent.Provider>
	);
};

export const useAuth = () => {
	return useContext(AuthContent);
};