import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState,useEffect } from "react";
import { useGetAllDevisesQuery, useDeleteDeviseMutation } from "../../app/services/deviseApi";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import Loader from "../loader/Loader";

function DeviseListEdit() {
  useEffect(() => {
    // Remettre le défilement en haut de la page à chaque chargement
    window.scrollTo(0, 0);
}, []);
  const navigate = useNavigate();
  const [deleteDevise] = useDeleteDeviseMutation();
  
  const [open, setOpen] = useState(false);
  const [selectedDevise, setSelectedDevise] = useState(null);
  const {
    data: devises,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllDevisesQuery();

  if (isLoading) {
    return <Loader />
  }

  const handleClickOpen = (devise) => {
    setSelectedDevise(devise);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDevise(null);
  };

  const handleDelete = () => {
    if (selectedDevise) {
      deleteDevise(selectedDevise.id).then(() => {
        setOpen(false);
        setSelectedDevise(null);
      });
    }
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        backgroundColor: "#ffff8d",
        pt:0,
        pb:2,
        marginTop:0,
       // padding: 4,
      }}
    >
      <Typography variant="h5">Gestion des devises</Typography>
      <Typography paragraph>
        Dans cette section, vous pouvez gérer les devises en ajoutant, supprimant ou modifiant les devises disponibles.
      </Typography>
      <Stack
        direction={"row"}
        spacing={2}
        useFlexGap
        flexWrap="wrap"
        justifyContent={"space-around"}
        sx={{
          backgroundColor: "white",
          borderRadius: 2,
          padding: 2,
        }}
      >
        {devises?.map((devise) => (
          <Card key={devise.id} sx={{ width: 240, maxWidth: 240,boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.4)", }}>
            <CardMedia
              component="img"
              height="120"
              // image={`https://exchange-maroc.com/laravel/storage/app/public/Devise/image/${devise.image}`}
               image= {`${process.env.REACT_APP_BASE_FILE_URL}/Devise/image/${devise.image}`}
               // image={`https://exchange-maroc.com/laravel/storage/Devise/image/${devise.image}`}
             // image={`http://127.0.0.1:8000/storage/Devise/image/${devise.image}`}
              alt={devise.name}
            />
            <CardHeader title={devise.name} subheader={devise.pays} />
            <CardContent>
            <Typography variant="body2" color="text.secondary">
                Prix d'achat : {devise.prixAchat} MAD
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Prix de vente : {devise.prixVente} MAD
              </Typography>
              
            </CardContent>
            <CardActions>
              <Button 
                size="small"
                onClick={() => {
                  navigate(`/admin/deviseedit/${devise.id}`);
                }}
              >
                <ModeEditIcon />
              </Button>
              <Button 
                size="small"
                onClick={() => handleClickOpen(devise)}
              >
                <DeleteIcon />
              </Button>
            </CardActions>
          </Card>
        ))}
      </Stack>
      <Button 
        size="small"
        onClick={() => {
          navigate("/admin/deviseadd");
        }}
      >
        <AddCircleIcon sx={{ fontSize: 60, color: "goldenrod" }} />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Supprimer une devise ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Cette opération supprimera la devise de votre application! Voulez-vous vraiment continuer la suppression ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={handleDelete} autoFocus>Continuer</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default DeviseListEdit;
