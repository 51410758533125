import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  useGetAllDevisesQuery,
  useUpdateDevisePricesMutation,
} from "../../app/services/deviseApi";
import Loader from "../loader/Loader";

function TableauCours() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data: devises, isLoading, isError } = useGetAllDevisesQuery();
  const [updateDevisePrices] = useUpdateDevisePricesMutation();
  const [editableDevises, setEditableDevises] = useState([]);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [modifiedDevises, setModifiedDevises] = useState([]); // Suivi des devises enregistrées avec succès

  useEffect(() => {
    if (devises) {
      // Préparer les devises pour être modifiables
      setEditableDevises(
        devises.map((devise) => ({
          ...devise,
          prixAchatTemp: devise.prixAchat,
          prixVenteTemp: devise.prixVente,
        }))
      );
    }
  }, [devises]);

  const handleEdit = (id, field, value) => {
    setEditableDevises((prev) =>
      prev.map((devise) =>
        devise.id === id ? { ...devise, [field]: value } : devise
      )
    );
  };

  const handleSave = async (id) => {
    setIsLoadingSave(true);
    const deviseToSave = editableDevises.find((devise) => devise.id === id);
    const { prixAchatTemp, prixVenteTemp, ...rest } = deviseToSave;

    // Appel API pour enregistrer la devise
    updateDevisePrices({
      id,
      devise: {
        prixAchat: prixAchatTemp,
        prixVente: prixVenteTemp,
      },
    }).then((response) => {
      setIsLoadingSave(false);
      if (!response.error) {
        // Ajouter l'ID de la devise dans la liste des devises modifiées avec succès
        setModifiedDevises((prev) => [...prev, id]);
      } else {
        console.error('Failed to update devise:', response.error);
      }
    });
  };

  const getCellStyle = (originalValue, currentValue, id) => {
    // Vérifie si la cellule a été modifiée et enregistrée
    if (modifiedDevises.includes(id)) {
      return { backgroundColor: "#c8e6c9" }; // Fond vert clair (réussi)
    }
    // Vérifie si la cellule est modifiée mais pas encore enregistrée
    return originalValue !== currentValue
      ? { backgroundColor: "#ffcccb" } // Fond rouge clair pour les cellules modifiées
      : {};
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return (
      <Typography color="error">
        Erreur lors du chargement des devises.
      </Typography>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ backgroundColor: "#ffff8d", pt: 0, pb: 2 }}>
      <Typography variant="h5">Gestion des devises</Typography>
      <Typography paragraph>
        Gérez les devises et mettez à jour les prix d'achat et de vente
        directement depuis le tableau.
      </Typography>
      <Box sx={{ backgroundColor: "white" }}>
        <TableContainer sx={{ mt: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Image</TableCell>
                <TableCell>Nom</TableCell>
                <TableCell>Pays</TableCell>
                <TableCell>Prix d'achat (MAD)</TableCell>
                <TableCell>Prix de vente (MAD)</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {editableDevises.map((devise) => (
                <TableRow key={devise.id}>
                  <TableCell>
                    <img
                      src={`${process.env.REACT_APP_BASE_FILE_URL}/Devise/image/${devise.image}`}
                      alt={devise.name}
                      style={{ width: 50, height: 50 }}
                    />
                  </TableCell>
                  <TableCell>{devise.name}</TableCell>
                  <TableCell>{devise.pays}</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      size="small"
                      value={devise.prixAchatTemp}
                      onChange={(e) =>
                        handleEdit(devise.id, "prixAchatTemp", e.target.value)
                      }
                      sx={getCellStyle(devise.prixAchat, devise.prixAchatTemp, devise.id)} // Applique la couleur de fond
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      size="small"
                      value={devise.prixVenteTemp}
                      onChange={(e) =>
                        handleEdit(devise.id, "prixVenteTemp", e.target.value)
                      }
                      sx={getCellStyle(devise.prixVente, devise.prixVenteTemp, devise.id)} // Applique la couleur de fond
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSave(devise.id)}
                      disabled={isLoadingSave}
                    >
                      {isLoadingSave ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        "Enregistrer"
                      )}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
}

export default TableauCours;
